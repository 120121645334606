import React from "react"
import Layout from "@components/layout";

// 言語用Jsonファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/customer-protection.json";
import langJa from "@content/locales/ja/customer-protection.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

import listSVG1 from '@images/icon_list1.svg';

const CustomerProtection = ( props ) => {

  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // Seo情報設定
  const pathName = 'customer-protection'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );
  
  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;
  
  // 使用時に戻す
  // const platDl1 = " https://portal.miltonmarkets.com/ja/downloads";
  // const platDl2 = " https://trade.mql5.com/trade?servers=MiltonTech-Platform&lang=ja";
  return (
<>
  {seoTag}
  <Layout>
    <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
      <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2">
        <div className=" text-left h1_filed">
          <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["cus-h1"]}</h1>
          <div className="text-base max-w-[680px]">
            <p>{postLang["cus-h1-txt"]}{postLang["cus-h1-txt_2"]}</p>
          </div>
        </div>
      </div>
    </div>
    <div className="max-w-xl mx-auto  pt-0 md:pt-5 md:max-w-5xl px-6 mb-10">
      <div className="text-left mt-10 md:mt-7 mb-10">
        <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px]">{postLang["cus-h2"]}{postLang["cus-h2_2"]}</h2>
      </div>
      <h3 className="font-semibold border-l-2 border-[#067CFA] pl-2.5 mb-6 text-lg">{postLang["cus-h4_2"]}</h3>
      <div className="mx-auto  max-w-xl md:max-w-5xl mb-10">
        <div className="w-full">
          <p className="mb-8">{postLang["cus-txt_8"]}<br></br>{postLang["cus-txt_8-1"]}</p>
        </div>
        <div className="w-full table">
          <div>
            <div className="mb-8 md:mb-5 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["cus-cap_1"]}</span></div>
            <div className="mb-8 md:mb-5 relative"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["cus-cap_2"]}</span></div>
            <div className="mb-8 md:mb-5 relative md:pb-0 pb-5"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["cus-cap_3"]}</span></div>
            <div className="mb-8 md:mb-5  relative md:pb-0 pb-5"><img src={listSVG1} className="absolute" alt="" /><span className="ml-7">{postLang["cus-cap_4"]}</span></div>
          </div>
        </div>
      </div>
      <h3 className="font-semibold border-l-2 border-[#067CFA] pl-2.5 mb-6 text-lg">{postLang["cus-h4"]}</h3>
      <p className="mb-5">{postLang["cus-txt_1"]}<br></br>{postLang["cus-txt_2"]}<br></br>{postLang["cus-txt_3"]}<br></br>{postLang["cus-txt_4"]}<br></br>{postLang["cus-txt_5"]}<br></br>{postLang["cus-txt_6"]}</p>
      <p className="text-[12px] mb-12 md:mb-[70px] ">{postLang["cus-txt_7"]}<br></br>{postLang["cus-txt_9"]}<br></br>{postLang["cus-txt_10"]}<br></br>{postLang["cus-txt_11"]}</p>
    </div>
    <div className="Feature  bg-fixed">
      <div className="mt-11 py-14 py-14  md:py-10 md:py-10"> 
      <div className="md:mx-auto max-w-5xl flex justify-center"><a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a> </div>
    </div></div>
  </Layout>
  </>
  )
}
export default CustomerProtection
